
ul.data-type-filter-text-options {
  list-style-type: none;
  width: 100%;
  height: 300px;
  border: 1px solid #eeeeee;
  overflow: auto;
  overflow-x: hidden;
  margin: 0;
  padding: 4px 6px;
}


ul.data-type-filter-boolean-options {
  list-style-type: none;
  width: 100%;
  border: 1px solid #eeeeee;
  margin: 0;
  padding: 4px 6px;
}


ul.data-type-filter-text-options,
ul.data-type-filter-boolean-options {
  li {
    margin: 0;
    padding: 0px;
    padding-bottom: 2px;
    
    label {
      margin: 0px;
    }

    input {
      margin-right: 5px;
    }
  }
}