
.report-send-card-body {
  
  .sending-log {
    display: block;
    border: 1px solid #ccc;
    margin-top: 20px;
    padding: 5px 10px;

    .title {
      font-weight: bold;
    }

    .sending-log-list {
      padding-top: 5px;

      .sending-log-item {
        display: block;
      }

      .sending-log-item-indent-1 {
        padding-left: 15px;
      }
      .sending-log-item-indent-2 {
        padding-left: 30px;
      }

      .sending-log-item-success {
        color: $green;
      }
      .sending-log-item-warning {
        color: $yellow;
      }
      .sending-log-item-error {
        color: $red;
      }

      .sending-log-item-risposta {
        color: $info;
        font-weight: bold;
      }
    }

  }

}