
.app-body.create-service-card-app-body {
  overflow-y: auto;
  max-height: calc(100vh - #{$header-height});

  > .container {

    @media (min-width: 576px) {
      max-width: 1110px;
    }
    @media (min-width: 768px) {
      max-width: 1120px;
    }
    @media (min-width: 992px) {
      max-width: 1130px;
    }
    @media (min-width: 1200px) {
      max-width: 1550px;
    }

  }
  
}

.create-service-card-container, .create-service-card-container-modal-footer {

  &.create-service-card-container-modal {
    overflow-y: auto;
    max-height: calc(100vh - 350px);
  }

  .service-selector {
    display: block;
    padding: 10px 10px;

    > h3 {
      margin-bottom: 35px;
    }

    .service-selector-group {
      display: block;
      padding-bottom: 20px;

      .group-name {
        color: $gray-900;
        font-size: 18px;
        margin-bottom: 0.5rem;
        font-weight: 500;
      }

      .group-types {
        display: block;
        display: flex;
        flex-direction: column;
        padding: 10px 10px;
    
        .service-container {
      
          .service-selector-type {
            // width: 30%;
            margin-bottom: 14px;
            border: $gray-200 1px solid;
            border-radius: 4px;
            padding: 10px 20px;
            cursor: pointer;
            display: flex;
            flex-direction: column;

            &:hover {
              border-color: $gray-500; 
            }

            &.active {
              border-color: $gray-800; 
            }

            .type-name {
              font-size: 15px;
              font-weight: 500;    
            }

            .type-description{
              flex-grow: 1;

            }

            .type-price{
              color: $gray-700;

              .senza-sconto {
                text-decoration: line-through;
              }
              .sconto {
                font-weight: bold;
              }
              
            }

          }        

        }

      
      }

    }
  }

  .service-container-settings {
    .service-options-container {
      padding-bottom: 20px;


      .service-option {
        .form-check.form-check-inline {
          align-items: flex-start;
          input {
            margin-top: 5px;
          }
          margin-bottom: 10px;
        }
      }
    }
  }

  .service-container-price {
    .vertical-hr{ 
      display: none;
      position: absolute;
      margin-left: -12px;
      margin-bottom: -10px;
      margin-top: -10px;

      border:         none;
      border-left:    1px solid rgba(0, 0, 0, 0.1);
      height:         100%;
      width:          1px;

      @media (min-width: 768px ) {
        display: block;
      }
    }
  }

  .service-total-price {
    padding-top: 20px;
    font-size: 15px;

    .service-total-price-label {
      color: $gray-600;
    }
    .service-total-price-value {

    }

    .prezzo-iniziale {
      .service-total-price-value {
        text-decoration: line-through;
      }
    }
    .sconto {
      margin-top: -10px;
    }
    .prezzo-finale {
      .service-total-price-value {
        font-size: 1.2em;
      }
    }
  }

  .service-contract-container {
    padding-top: 40px;

    .service-contract-iframe {
      display: block;
      border: 1px solid $gray-400;
      height: 25vh;
      max-height: 200px;
      min-height: 100px;
      width: 100%;
    }
    .service-contract-download-link {
      float: right;
      cursor: pointer;
    }

  }

  .service-payment-container {
    float: right;
    padding-top: 5px;
  }

  .service-grand-total-price {
    padding-top: 20px;
    font-size: 15px;
  
    .service-total-price-label {
      color: $gray-600;
    }
    .service-total-price-value {
      font-size: 1.2em;
  
      .service-total-price-value-iva {
        font-size: 0.7em;
      }
    }   
  }
  
  
}

