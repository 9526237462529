

$table-selection-color: lighten(theme-color("primary"), 30%);

// $table-view-bg: #f9f9fa;
// $table-view-bg-odd: darken($table-view-bg, 2%);

$table-view-bg: white;
$table-view-bg-odd: rgba(4, 169, 245, 0.05);


.table-view-grid-container {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  margin: 0px;
  outline: none;
  flex-grow: 1;
  flex-shrink: 1;

  // PRIVACY
  &.table-view-grid-container-privacy {
    .table-view-grid-bottom-right {
      filter: blur(5px);
    }
  }
}


.table-view.table-view-grid {
  outline: none;

  // this problem was fixed with the use of a table-view-scroll-smoother div
  // // this is needed because will-change: transform; causes problem with scroll position sync
  // will-change: unset !important;
}



//
// Basic Bootstrap table
//

@mixin table-view-row-variant($state, $background) {
  // Exact selectors below required to override `.table-striped` and prevent
  // inheritance to nested tables.
  .table-view-#{$state} {
    &,
    .table-view-cell {
      background-color: $background;
    }
  }

  // Hover states for `.table-hover`
  // Note: this is not available for cells or rows within `thead` or `tfoot`.
  .table-view-hover {
    $hover-background: darken($background, 5%);

    .table-view-#{$state} {
      @include hover {
        background-color: $hover-background;

        .table-view-cell {
          background-color: $hover-background;
        }
      }
    }
  }
}

.table-view {
  .table-view-cell {
    background-color: $table-view-bg; 
    padding: $table-cell-padding;
    position: relative;  
  }
}

.table-view-header-cell {
  border-top: $table-border-width solid $table-border-color;
  word-wrap: break-word;
  margin-bottom: auto;
}

.table-view-sm {
  .table-view-cell {
    padding: $table-cell-padding-sm;
  }
}


// Bordered version
.table-view-bordered {
  .table-view-cell {
    border-bottom: $table-border-width solid $table-border-color;
    // border-right: $table-border-width solid $table-border-color;
  }
  &.table-view-grid .ReactVirtualized__Grid__innerScrollContainer {
    // border-left: $table-border-width solid $table-border-color;    
    // border-right: $table-border-width solid $table-border-color;    
  }
  .table-view-multigrid-show-horizontal-scrollbar &.table-view-grid .ReactVirtualized__Grid__innerScrollContainer {
    // border-right: none;
  }

  .table-view-header-cell.table-view-header-cell-last-row {
    border-bottom-width: (2 * $table-border-width);
  }

  .table-view-footer-cell {
    border-top: (2 * $table-border-width) solid $table-border-color;
  }
}


// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table-view-striped {
  .table-view-cell.table-view-row-odd {
    // background-color: $table-accent-bg;
    background-color: $table-view-bg-odd;
  }
}


// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-view-hover {
  .table-view-cell:not(.table-view-header-cell) {
    @include hover {
      background-color: $table-hover-bg;
    }
  }
}


// backgrounds

@each $color, $value in $theme-colors {
  @include table-view-row-variant($color, theme-color-level($color, -9));
}

@include table-view-row-variant(active, $table-active-bg);


// Dark styles

// stylelint-disable-next-line no-duplicate-selectors
.table-view-dark {
  .table-view-cell {
    color: $table-dark-color;
    background-color: $table-dark-bg;
    border-color: $table-dark-border-color;
  }
}

.table-view-light {
  .table-view-cell {
    color: $table-head-color;
    background-color: $table-head-bg;
    border-color: $table-border-color;
  }
}

.table-view-dark {
  .table-view-cell {
    border-color: $table-dark-border-color;
  }

  &.table-view-bordered {
    .table-view-cell {
      border: 0;
    }
  }

  &.table-view-striped {
    .table-view-cell.table-view-row-odd {
      // background-color: $table-dark-accent-bg;
      background-color: lighten($table-dark-bg, 5%);
    }
  }

  &.table-view-hover {
    .table-view-cell:not(.table-view-header-cell) {
      @include hover {
        background-color: $table-dark-hover-bg;
      }
    }
  }
}













// TODO: 

.sortable-helper.table-view-header.table-view-cell {
  // border-right: $table-border-width solid $table-border-color;
  // border-left: $table-border-width solid $table-border-color;
  border-top: $table-border-width solid $table-border-color;
}













// cell style
.table-view .table-view-cell {
  cursor: default;
}
.table-view .table-view-cell:not(.table-view-header-cell) {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  user-select: none;
  display: table-cell;
  ::selection {
    background: lighten($yellow, 10%);
  }
}
// header style
.table-view .table-view-header-cell {
  font-weight: 600;
  user-select: none;
  padding-right: 14px !important;
  cursor: pointer;

  .sort-indicator {
    position: absolute;
    top: 0px;
    right: 1px;
    color: #ccc;

    &.sort-indicator-asc, &.sort-indicator-desc { 
      color: #666;
    }
  }

  .filter-indicator {
    visibility: hidden;
    position: absolute;
    top: 15px;
    right: 1px;
    a {
      color: #ccc;
      outline: none;
      &:hover {
        color: #aaa;
      }
    }
  }
  &:hover .filter-indicator {
    visibility: visible;
  }
  .filter-indicator.filter-enabled {
    visibility: visible;
    a, &:hover a {
      color: theme-color("primary");
    }
  }

  .filter-dropdown-container {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 14px;
    height: 1px;
  }
}


// empty table
.table-view {
  .table-view-cell {
    &.table-view-loading,
    &.table-view-loading-error,
    &.table-view-no-rows {
      display: block;
      // border-left: $table-border-width solid $table-border-color;
      width: 100%;
      max-width: unset !important;
    }
  }
}


// fixed columns separator
.table-view {
  &.table-view-grid-top-left,
  &.table-view-grid-bottom-left,
  &.table-view-grid-footer-left {
    // border-right: $table-border-width solid $table-border-color;
    .ReactVirtualized__Grid__innerScrollContainer {
      // border-right: $table-border-width solid $table-border-color;
    }
  }
}


// header column resizer
.table-view-header-cell {
  position: relative;

  .table-view-column-resize {
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin-right: -2px;
    width: 4px;
    cursor: ew-resize;

    &.table-view-column-resize-dragging {
      visibility: hidden;
    }
  }
}


// cell selection
.table-view .table-view-cell.table-view-row-selected {
  &, &:hover {
    background-color: $table-selection-color;
  }
}
.table-view .table-view-cell.table-view-column-selected {
  &, &:hover {
    background-color: $table-selection-color;
  }
}


// cell buttons
.table-view { 
  .cell-buttons-container {
    display: inline-flex;
    visibility: hidden;
    position: absolute;
    right: 0px;
    padding-right: 3px;
    top: 0px;
    bottom: 0px;
    font-size: 16px;
    line-height: 20px;
    z-index: 10;
    background-color: transparent;
  }
  .table-view-cell:hover .cell-buttons-container,
  .table-view-cell.buttons-always-visible .cell-buttons-container {
    visibility: visible;
  }

  .cell-buttons-container > * {
    padding-left: 3px;
    margin-top: auto;
    margin-bottom: auto;
    background-color: transparent;
  }

  .cell-buttons-container *:focus {
    outline: none;
  }
  .buttons-always-visible .cell-buttons-container > * {
    display: none;
    &.button-always-visible {
      display: inline-block;
    }
  }
}


.table-view {
  &.table-view-grid-top-left,
  &.table-view-grid-top-right,
  &.table-view-grid-bottom-left,
  &.table-view-grid-footer-left {
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &.table-view-grid-bottom-right,
  &.table-view-grid-footer-right {
    @include scrollbar-style();
  }
  .table-view-grid-container-with-footer &.table-view-grid-bottom-right {
    &::-webkit-scrollbar:horizontal {
      display: none;
    }
  }

  // to make space for the horizontal scrollbar
  .table-view-grid-container-with-footer &.table-view-grid-footer-right {
    height: 100% !important;
  }
}
// used to compute scrollbar size
.table-view-scrollbar-size-helper-div {
  @include scrollbar-style();
}


// filter popover style
.table-filter-dropdown.table-filter-dropdown-DATETIME {
  max-width: 400px;
}


// inline editing
.table-inline-editing-popover {
  max-width: unset;
}

.table-inline-editing-container {
  display: flex;
  flex-direction: row;
  
  .table-inline-editing-input-container {
    margin-bottom: auto;
    max-width: 275px;
    position: relative;

    .loading-spinner {
      position: absolute;
      right: 2px;
      top: 0px;
    }

    .form-group {
      margin-bottom: 14px;

      label {
        margin-bottom: 2px;
      }
    }
  }

  &.table-inline-editing-container-BOOLEAN {
    .table-inline-editing-input-container {
      min-width: 40px;
      padding-left: 10px;
      padding-top: 4px;

      label {
        padding-top: 2px;
        margin-right: 6px;        
      }
      input {
        position: initial;
        padding-right: 12px;        
      }

      .loading-spinner {
        top: 30px;
      }
    }
  }

  &.table-inline-editing-container-TEXT_ARRAY,
  &.table-inline-editing-container-ENUM {
    .table-inline-editing-input-container {
      min-width: 200px;
    }
  }

  &.table-inline-editing-container-FORMATTED_TEXT {
    .table-inline-editing-input-container {
      width: 350px;
      max-width: 90vw;
    }
  }


  &.table-inline-editing-container-data-validation {
    .table-inline-editing-input-container {
      min-width: 200px;
    }    
  }

  .table-inline-editing-buttons {
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    
    > *:not(:first-child) {
      margin-top: 10px;
    }
  }
}



// footer 
.table-view .table-view-footer-cell {

  span.value {
    margin-right: 10px;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    user-select: none;
  }

  div.select-outer-container {
    height: 0;
    width: 100%;

    div.select-inner-container {
      height: 0px;
      float: right;
      margin-top: -2px;
      margin-right: -5px;

      select {
        position: relative;
        width: 13px;
        border: 0px;
        outline: 0px;
        -webkit-appearance: menulist;
        text-transform: uppercase;
        font-size: 0.7em;
        font-weight: 500;
        color: #888;

        &:hover {
          width: auto;
        }      
      }
    }
  }
}


// column reorder
.table-view-column-sorting {
  .table-view-cell {
    // border-left: $table-border-width solid $table-border-color;
    margin-left: -1px;
  }
  .table-view-column-sort-dragging {
    z-index: 1000;
  }
  // .table-view-cell:not(.table-view-column-sort-dragging) {
  //   transition: left 0.2s ease-in-out;
  // }
}



// FIX SCROLLING ASYNC PROBLEM
// this makes the scrolling events async, causing out of sync scrolling of the header/body
// https://github.com/bvaughn/react-virtualized/issues/291#issuecomment-349641071
.table-view-grid-container .table-view-scroll-smoother {
  z-index: 1;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  will-change: transform;
}



.ReactVirtualized__Grid.table-view.table-view-striped .table-view-cell.table-view-row-hover {
  background-color: #f15a241a !important
}


.ReactVirtualized__Grid.table-view.table-view-striped .table-view-cell {
  & > .MuiButton-root {
    height: 100%;
  }
}