.rcqui-container {
  .rcqui-section {
    .rcqui-section-title {
      font-size: 15px;
      font-weight: 600;
      padding-top: 20px;
      padding-bottom: 10px;
    }
    .rcqui-section-data-content {
      table {
        td.rcqui-no-cell {
          border: none;
        }
        td {
          padding: 3px 4px;
          input,
          select {
            padding: 3px 4px;
          }
          .Select > .Select-control {
            padding: 3px 4px;
            // height: 21px;
            height: 29px;
          }
          select.form-control:not([size]):not([multiple]) {
            height: auto;
          }
          .Select > .Select-control .Select-value {
            padding: 3px 4px;
          }
          .Select-input {
            height: 21px;
          }
        }

        td,
        th {
          word-wrap: break-word;
          white-space: normal;
        }
      }
    }
  }
}

.report-card-modal-quadri {
  @media (min-width: 1200px) {
    max-width: 1160px;
    margin: 1.75rem auto;
  }
  @media (min-width: 1300px) {
    max-width: 1260px;
    margin: 1.75rem auto;
  }
  @media (min-width: 1400px) {
    max-width: 1360px;
    margin: 1.75rem auto;
  }
}
