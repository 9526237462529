
.form-page {

  height: 100vh;
  display: flex;
  
  > .container {
    overflow-y: auto;
    max-height: 100vh;
    padding-top: 1.5rem;
  }
  .form-links {
    padding-top: 30px;
  }
}
