// Here you can add other styles

// Override Boostrap variables
// @import "bootstrap-variables";
// @import "core-variables";

// Fix: Scrolling elastic scroll
html {
  overflow-y: hidden;
  overflow-x: hidden;
  overflow: hidden;
}
body {
  overflow-y: auto;
  overflow-x: auto;
  overflow: auto;
}

// Fix: table in table
.table .table {
  background-color: $table-bg; // Reset for nesting within parents with `background-color`.
}

// Fix: secondary button
.btn-outline-secondary {
  color: $gray-800;
}

// Fix anchor without href
a.a-link-without-href {
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}

// Pull right
.pull-right {
  float: right;
}

// Better vertical position of label in horizontal forms
.form-horizontal label {
  margin-top: 7px;
}
// checkbox...
.form-horizontal .form-check-input {
  margin-top: 12px;
}

// context menu

.react-contextmenu {
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 16px;
  color: #373a3c;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  outline: none;
  opacity: 0;
  pointer-events: none;
  transition: opacity 250ms ease !important;
  z-index: 100000;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
}

.react-contextmenu-item {
  padding: 3px 20px;
  font-weight: 400;
  line-height: 1.5;
  color: #373a3c;
  text-align: inherit;
  white-space: nowrap;
  background: 0 0;
  border: 0;
  cursor: pointer;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: $blue;
  border-color: darken($blue, 20%);
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  color: #878a8c;
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item--divider {
  margin-bottom: 3px;
  padding: 2px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
}
.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
}

.react-contextmenu-item.react-contextmenu-submenu
  > .react-contextmenu-item:after {
  content: "▶";
  display: inline-block;
  position: absolute;
  right: 7px;
  // box-sizing: border-box;
  // position: absolute;
  // top: 50%;
  // right: 1rem;
  // display: block;
  // width: 8px;
  // height: 8px;
  // padding: 0;
  // margin-top: -4px;
  // content: "";
  // background-image: url(data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' v…352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E);
  // background-repeat: no-repeat;
  // background-position: center;
}

// input type select - no border
select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  // border-radius: 0px;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
}

// react-select without dropdown
.react-select-hide-options {
  .Select-arrow-zone {
    display: none;
  }
  .Select-menu-outer {
    display: none;
  }
  &.Select--multi .Select-clear-zone {
    padding-right: 5px;
  }
}

// Slider
.rc-slider {
  .rc-slider-rail {
    border-radius: 0px;
  }
  .rc-slider-track {
    border-radius: 0px;
    background-color: theme-color("primary");
  }
  .rc-slider-handle {
    border-radius: 0px;
    margin-left: -4px;
    width: 8px;
    border-color: theme-color("primary");
    &:hover,
    &:focus {
      border-color: theme-color("primary");
      outline: none;
      box-shadow: none;
      border-color: darken(theme-color("primary"), 5%);
    }
  }
}

// Fix select input
.Select-input {
  width: 100%;
  padding: 0px;
}
.Select-input > input {
  width: 100% !important;
  padding-top: 8px;
  padding-right: 10px;
  padding-bottom: 12px;
  padding-left: 10px;
}

.Select {
  > .Select-control {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    height: 43px;
    // background: #f4f7fa;

    .Select-value {
      padding: 10px 20px;
      line-height: 1.5;
    }
    .Select-input input {
      padding: 10px 20px;
    }
  }
}

.Select.is-disabled > .Select-control {
  background: #f8fafc;
  color: #a4b0b7;

  .Select-value label {
    color: #a4b0b7;
  }
}

// CARD HEADER BOLD
.card .card-header {
  // font-weight: bold;
  font-size: 18px;
  color: #111;
}

.table-view-cell > .btn {
  height: 29px;
  padding: 6px 1px;
  font-size: 12px;
  margin-top: -4px;
  margin-left: -3px;
  margin-right: -3px;
}

//FIX COLOR DROPDOWN
.pcoded-header.header-dark .dropdown.drp-user.show:before {
  color: $brand-main-color;
}
.pcoded-header.header-dark .dropdown .profile-notification .pro-head {
  background: $brand-main-color;
}

// FIX CHAT LIST
.h-list-body {
  .userlist-box {
    &.active {
      background: white;

      .media-body:after {
        content: "";
        background-color: $brand-main-color;
        position: absolute;
        left: 8px;
        top: 20%;
        width: 4px;
        height: 60%;
      }
    }
  }
}

// Ace editor
div.ace_editor {
  border: $table-border-width solid $table-border-color;

  //https://github.com/thlorenz/brace/issues/126
  font-feature-settings: normal;

  &.ace-chrome .ace_gutter {
    background: $card-cap-bg;
  }
  &.ace-chrome:not(.ace_focus) .ace_gutter-active-line {
    opacity: 0;
  }

  .ace_layer.ace_cursor-layer.ace_hidden-cursors .ace_cursor {
    opacity: 0;
  }

  &.ace-chrome:not(.ace_focus) {
    .ace_layer.ace_marker-layer {
      opacity: 0;
    }
  }

  &.function-editor-disabled {
    border: none;

    .ace_cursor {
      opacity: 0;
    }
    .ace_gutter {
      display: none;
    }
    .ace_scroller {
      left: 0px !important;
    }
  }
}

//fix warning badge
.badge {
  font-weight: 600;
}
.badge-warning {
  color: #212529;
}

// fix radio
.radio input[type="radio"] + .form-check-label:after,
.radio input[type="radio"] + .cr:after {
  top: 15px;
}
.radio input[type="radio"]:checked + .form-check-label:after,
.radio input[type="radio"]:checked + .cr:after {
  background: #1de9b6;
}

// material ui
.row + .row {
  margin-top: 30px;
}
input,
textarea,
select {
  &.form-control {
    background-color: unset;
  }
}
.ReactVirtualized__Grid.table-view {
  //.table-view-bordered {
  .table-view-cell {
    border: 0px;

    &.table-view-column-0 {
      &,
      &.table-view-header-cell {
        padding-left: 16px;
      }
    }
  }
  &.table-view-striped .table-view-cell.table-view-row-odd {
    background-color: transparent; // #a1a1a111;
  }
}
