

.ServiziAcquistatiPartner-table-container {
  .table-view-cell {
    border-right: 1px solid #dee2e6;

    &:not(.table-view-column-0) {
      text-align: center;
    }
  }
}
