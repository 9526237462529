.report-card-modal {
  @media (min-width: 576px) {
    max-width: unset;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  @media (min-width: 992px) {
    max-width: 950px;
    margin: 1.75rem auto;
  }

  .modal-body {
    background-color: $theme-background;
  }
}

.report-card-modal,
.report-card-wrapper {
  .modal-header {
    position: sticky;
    top: 0;
    z-index: 2;

    background-color: white;
    padding: 8px 16px;
  }

  .sw-theme-default .Loki > ul.step-anchor {
    position: sticky;
    top: 66px;
    z-index: 1;

    margin-top: -20px;
    margin-left: -15px;
    margin-right: -15px;

    max-height: 210px;
    overflow-y: auto;

    .nav-link {
      padding: 12px 20px;
      h6 {
        margin-bottom: 2px;
      }
    }
  }

  .sw-theme-default .Loki > ul.step-anchor > li > a {
    cursor: pointer;
  }

  .sw-theme-default .Loki > ul.step-anchor > li {
    &:not(.active):not(.done):not(.with-error) {
      & > a::after {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        background: $gray-400;
      }
    }
  }

  .sw-theme-default .Loki > ul.step-anchor > li.with-error > a::after {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    background: $red;
  }
}

.report-card-body {
  .installation-name-container {
    font-weight: 700;
    font-size: 1.1em;
  }

  .license-container {
    color: $gray-600;
    margin-top: 2px;
    .license-name {
      color: $gray-800;
    }
  }
  .type-container {
    color: $gray-600;
    margin-top: 2px;
    .type-name {
      color: $gray-800;
    }
  }
  .power-container {
    color: $gray-600;
    margin-top: 2px;
    .power-kw {
      color: $gray-800;
    }
  }

  input.form-check-input {
    margin-left: 0px;
  }

  .readings-table {
    width: 100%;
    max-width: 600px;

    &.readings-table-con-dati-contatori {
      max-width: 900px;
      td:nth-child(3) {
        padding-left: 8px;
      }
    }

    .form-check {
      margin-top: 10px;
    }

    th.first-column {
      width: 334px; // it is used as a max width
    }

    td {
      vertical-align: baseline;
    }

    td,
    th {
      min-width: 135px;
      &.first-column {
        min-width: 150px;
      }

      &.separator1 {
        min-width: unset;
        border-right: 1px solid $gray-400;
      }
      &.separator2 {
        min-width: unset;
      }
    }
  }

  .step-letture-readings-table {
    th.first-column {
      width: 150px; // it is used as a max width
    }
    td,
    th {
      padding: 1px 2px;
    }
  }
  .step-letture-readings-table-container {
    overflow-x: auto;
  }

  .report-card-table-lista-contatori,
  .report-card-table-lista-costanti {
    input,
    select {
      padding: 6px 10px;
      height: unset !important;
    }

    td,
    th {
      vertical-align: top;
    }

    tr td {
      color: $gray-800;
    }

    tr.removed-row {
      position: relative;

      td {
        color: $gray-400;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 5px;
        right: 60px;
        border-bottom: 1px solid $gray-500;
        margin-top: 20px;
      }
    }
  }

  .report-card-table-lista-costanti {
    td.proporzionale-checkbox {
      input {
        margin-top: 12px;
        margin-left: 50px;
      }
    }
  }

  .formule-editor-container {
    > .row:not(:last-child) {
      border-bottom: 1px $gray-400 solid;
      margin-bottom: 25px;
    }

    input {
      padding: 6px 10px;
      height: unset !important;
    }

    code {
      color: rgb(88, 92, 246);
    }
  }

  .codice-error-container {
    height: 34px;
    > div {
      margin-top: 14px;
      position: absolute;
    }
  }

  .report-riepilogo {
    .report-riepilogo-frontespizio {
      .tipo-dichiarante {
        font-size: 1.4em;
      }
      .periodo-anno {
        font-size: 1.2em;
      }
      .dichiarante-info {
        margin-top: 20px;
        margin-bottom: 20px;

        .info-title {
          color: $gray-600;
        }
        .info-value {
          font-size: 1.1em;
        }
      }
    }
    .quadri-container {
      table {
        // .td-quadro {
        // }
        .td-quadro-title {
          font-style: italic;
        }
        .td-totale {
          text-align: right;
          padding-left: 40px;
        }
      }
    }

    .quadri-mensili-container {
      margin-top: 20px;
      table {
        .td-mese {
          font-style: italic;
        }
        .td-totale {
          text-align: right;
          padding-left: 40px;
        }
        .td-editing {
          max-width: 150px;
          width: 170px;
          padding: 4px 10px;
        }
        .tr-totale {
          font-weight: 600;
        }
      }
    }

    .quadri-container,
    .quadri-mensili-container {
      table {
        td,
        th {
          padding: 6px 10px;
        }
      }

      $border-radius: 0.25rem;
      $border: 1px solid #ced4da;

      table {
        border: $border;
        border-collapse: separate;
        border-left: 0;
        border-radius: $border-radius;
        border-spacing: 0px;
      }
      thead {
        // display: table-header-group;
        // vertical-align: middle;
        border-color: inherit;
        border-collapse: separate;
      }
      tr {
        // display: table-row;
        // vertical-align: inherit;
        border-color: inherit;
      }
      th,
      td {
        // padding: 5px 4px 6px 4px;
        // text-align: left;
        // vertical-align: top;
        border-left: $border;
        border-top: $border;
      }
      thead:first-child tr:first-child th,
      tbody:first-child tr:first-child td {
        border-top: 0;
      }
      thead:first-child tr:first-child th:first-child,
      tbody:first-child tr:first-child td:first-child {
        border-radius: $border-radius 0 0 0;
      }
      thead:last-child tr:last-child th:first-child,
      tbody:last-child tr:last-child td:first-child {
        border-radius: 0 0 0 $border-radius;
      }
    }
  }

  .report-card-steps-div-with-inline-input {
    input,
    select {
      display: inline;
      margin-left: 10px;
      margin-right: 10px;
      width: auto;
    }
  }

  .sw-toolbar-bottom {
    display: flex;
    align-items: start;

    .btn {
      padding: 6px 14px;
      font-size: 14px;
      line-height: 1.5;

      .MuiIcon-root {
        font-size: 20px;
        margin-bottom: -6px;
      }
    }
  }
}
