
table.notifiche-enabled-settings {
 
  margin: 15px 10px;

  tr td {
    padding: 5px 10px;
    position: relative;

    &:first-child {
      width: 1000px;
    }

    .btn {
      padding: 4px 8px;
      font-size: 11px;
    }

    .notifiche-enabled-settings-saving-loading {
      position: absolute;
      right: -12px;
      color: $gray-400;
    }
  }
 
}