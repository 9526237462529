.datetime-picker-input {
  display: inline-block;

  &.datetime-picker-input-full-width {
    width: 100%;
    > input {
      width: 100%;
    }
  }  
}

.datetime-picker-input-overlay-wrapper {
  position: relative;
}

.datetime-picker-input-overlay {
  left: 0;
  z-index: 1000;
  position: absolute;
  background: white;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}


.time-picker-container {
  text-align: center;
  min-width: 200px;
  padding: 10px 2px;

  > span {
    vertical-align: middle;
  }

  .time-picker-select-wrapper {
    display: inline-block;
    vertical-align: middle;    

    .time-picker-arrow {
      cursor: pointer;
      color: #8b9898;
      width: 20px;
      height: 20px;
      background-size: 50%;
      background-repeat: no-repeat;
      background-position: center;
      margin-left: auto;
      margin-right: auto;

      &:hover {
        opacity: 0.8;
      }
    }
    .time-picker-arrow-up {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAkCAYAAADPRbkKAAAABGdBTUEAALGPC/xhBQAAAVlpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IlhNUCBDb3JlIDUuNC4wIj4KICAgPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICAgICAgPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIKICAgICAgICAgICAgeG1sbnM6dGlmZj0iaHR0cDovL25zLmFkb2JlLmNvbS90aWZmLzEuMC8iPgogICAgICAgICA8dGlmZjpPcmllbnRhdGlvbj4xPC90aWZmOk9yaWVudGF0aW9uPgogICAgICA8L3JkZjpEZXNjcmlwdGlvbj4KICAgPC9yZGY6UkRGPgo8L3g6eG1wbWV0YT4KTMInWQAAAbJJREFUWAnV2F9OwkAQx/GtkXgBvYQcR+sBOAAXUV9MgAAJt9JLcALbh3WnphV+y7r/ZrqFF2wi7XyZD0qqlODjdbd7ed9ua8FLqErq5B+Hw0PTtl9aKX03m82Xi8VR4lo3Eielc3637UZrfa+0ppCV1HVEAoiOGfypH9qE1FKU2AkNdOjdP31U1VGCEvsGmqZZd3ROh6efhSixBhAT86F9xtn7YwlKbISIjvngftI73Q988ZmZEtsGiI53eCpipsQS4KODm+CklE0omA5WMFHK3kAwHQxgopQVEEvHbsj/B5dMKJkOVmRSSt5AMh0MyKSUFJBLx25IpxRNiI0OViRSit4AGx0MSKQUFcBNx27Q9dt+7/wuhb9Px8GExOjgVJGUgjcgRgcDIikFBUjTsRvCKXkJjUYHKwIpeTcwGh0MCKT0b8DYdOwGPyUnoWJ0sMJDybmBYnQwwEPpYkBpOnaDm5JFaDJ0sMJBydpAdxvQrA1fX/zYQeksoKNjbgMWH9YxAN0MwO9KA6HJ0sEYoDRsYLJ0MAAodQFTp2M3/FGqroYOVhhKxv/j7dXQwYDfv5SrH2VBcWQdmbWjAAAAAElFTkSuQmCC');
    }
    .time-picker-arrow-down {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAkCAYAAADPRbkKAAAABGdBTUEAALGPC/xhBQAAAVlpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IlhNUCBDb3JlIDUuNC4wIj4KICAgPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICAgICAgPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIKICAgICAgICAgICAgeG1sbnM6dGlmZj0iaHR0cDovL25zLmFkb2JlLmNvbS90aWZmLzEuMC8iPgogICAgICAgICA8dGlmZjpPcmllbnRhdGlvbj4xPC90aWZmOk9yaWVudGF0aW9uPgogICAgICA8L3JkZjpEZXNjcmlwdGlvbj4KICAgPC9yZGY6UkRGPgo8L3g6eG1wbWV0YT4KTMInWQAAAadJREFUWAnV2dFtwjAQBuBzpKQTlgGQIGoj8VI6Rdt3YIO2dDLYgfpHHKqgTuzzObEjIUuJ7dwffxAC5n2z+TwRzajAzRjzXZExnX0diqvf1tzUdVet2/Zoquq5tAAVUbeazw+2JVovFnssRykhUOtL257rPQdA4ViOIihd6PDFvgbAcpRAiencBcCO3Cn9pfNvAOzMltINHWeAXCnd0nEGwIHcKBmiPX/qcOHcXt/EvIPbbCiBTtM471POALlQctHhC+0MgA5TU+qj4xUAnSajNEDHO8BUlIboeAdAx7Ep+dAJCoDOo1HypBMcYCxKvnSCA2BAakohdEQBMCgZpUA64gCghGXmCbTaUDp8XjsufMP3Es0nOAkdrloUAIPVKAnpRAfQoiSlEx0AE8RSiqGjEgCTiClF0lELIKUUS0ctACYKpaRBRzUAJvOmpERHPYAvJS066gEw4RAlTTpJAmBSFyV75z72PZxzQaGt+E7sOpGL0sn+jI9jrnHS/eoBUMgdJWN+XpfLL2mRfeOSBMAJmRLoPNT1U18R2R772G4f33a7pH9f/QKtUpy0+zZTKAAAAABJRU5ErkJggg==');
    }
    .time-picker-arrow-disabled {
      &, &:hover {
        opacity: 0.2;
      }
    }

    .time-picker-label {
      position: relative;
      display: inline-block;
      height: 30px;
      padding: 5px 6px;
      border: 1px solid transparent;
      box-sizing: border-box;
      cursor: pointer;
      text-align: center;

      &:hover {
        border: 1px solid #ddd;
        border-radius: 2px;
        background-color: #f5f5f5;
      }

      select {
        position: absolute;
        top: 6px;
        left: 0;
        cursor: pointer;
        opacity: 0;
        filter: opacity(0);
        -ms-filter: "alpha(opacity=0)";
      }
    }

  }
}


// date picker style fixes!
.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #f0f8ff;
  border-radius: 0px;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: theme-color("primary");
  border-radius: 0px;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: darken(theme-color("primary"), 10%);
}
.DayPicker-Day {
  padding: 5px 8px;
}
.DayPicker-Weekday {
  padding: 5px;
}
