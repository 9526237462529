.table-container {
  position: relative;
  width: 100%;
  height: 100%;

  .sheet-table {
    outline: none;
    border-left: $table-border-width solid $table-border-color;
    border-right: $table-border-width solid $table-border-color;
    border-bottom: $table-border-width solid $table-border-color;

    &.sheet-table-top-left {
      border-right: $table-border-width solid $table-border-color;
      border-bottom: $table-border-width solid $table-border-color;
      .sheet-table-cell {
        border-top: $table-border-width solid $table-border-color;
        border-left: $table-border-width solid $table-border-color;
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &.sheet-table-top-right {
      // border-right: $table-border-width solid $table-border-color;
      .ReactVirtualized__Grid__innerScrollContainer {
        border-bottom: $table-border-width solid $table-border-color;
        // margin-right: -1px;
      }
      .sheet-table-cell {
        border-top: $table-border-width solid $table-border-color;
        border-right: $table-border-width solid $table-border-color;
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &.sheet-table-bottom-left {
      .ReactVirtualized__Grid__innerScrollContainer {
        border-right: $table-border-width solid $table-border-color;
        // margin-bottom: -1px;
      }
      // border-bottom: $table-border-width solid $table-border-color;
      .sheet-table-cell {
        border-left: $table-border-width solid $table-border-color;
        border-bottom: $table-border-width solid $table-border-color;
      }
    }

    &.sheet-table-bottom-right {
      // border-bottom: $table-border-width solid $table-border-color;
      // border-right: $table-border-width solid $table-border-color;
      .ReactVirtualized__Grid__innerScrollContainer {
        // margin-bottom: -1px;
        // margin-right: -1px;
      }
      .sheet-table-cell {
        border-right: $table-border-width solid $table-border-color;
        border-bottom: $table-border-width solid $table-border-color;
      }
    }

    .sheet-table-cell {
      padding: 5px 5px;
      display: table-cell;
      vertical-align: middle;
      background: white;
    }

    &.sheet-table-bottom-right {
      .sheet-table-cell {
        text-align: left;
      }
    }


    // header cells
    &.sheet-table-top-left, &.sheet-table-top-right, &.sheet-table-bottom-left {
      .sheet-table-cell {
        background: darken($gray-100, 2%);
      }
    }
    .sheet-table-cell-header {
      background: darken($gray-100, 2%);
    }
    &.sheet-table-top-right {
      .sheet-table-cell {
        text-align: left;
      }
    }
    &.sheet-table-top-right, &.sheet-table-bottom-right {
      .sheet-table-cell-header {
        background: darken($gray-100, 2%);
        text-align: left;
      }
    }
    
    &.sheet-table-top-left, &.sheet-table-bottom-left {
      .sheet-table-cell {
        white-space: nowrap;
      }
    }
          

  }


  .table-smoother {
    z-index: 1;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    will-change: transform;
  }

  .sheet-table-cell .btn {
    margin-top: -3px;
  }
  
} 

