.tabella-importa-file-xml-firmati {
  td {
    white-space: normal;
  }
  td.importa {
    text-align: center;
    vertical-align: inherit;
    
    input {
      position: relative;
      margin: auto;
    }
  }
}