
.installation-load-card-result {

  .result-section {
    .result-section-title {
      font-weight: bold;
    }

    .result-error-details {
      margin-left: 20px;
    }
  }

  .result-importazione-riepilogo {
    .title {
      font-weight: bold;
    }
    .list {
      margin-left: 20px;
    }
  }

}
