@import "../scss/style";

//fix
.main .animated.fadeIn .container-fluid {
  padding-right: 15px;
  padding-left: 15px;
}

// Pages
@import "pages/base/FormPage";
@import "pages/base/InfoPage";

// Elements

@import "elements/DatetimePicker";
@import "elements/DataTableOLD";

@import "elements/DataTable/DataTable";
@import "elements/DataTable/DataTypeFilter";

// Components

@import "components/Header";
@import "components/Sidebar";
@import "components/CreateServiceCard";
@import "components/PaymentInfoCard";
@import "components/Installations";
@import "components/InstallationDetails";
@import "components/InstallationStateButtons";
@import "components/ReportCard";
@import "../../../energix-frontend/src/components/features/report/old/ReportCard";
@import "components/ReportSendCard";
@import "components/ReportCardQuadriUI";
@import "../../../energix-frontend/src/components/features/report/old/ReportCardQuadriUI";
@import "components/Partner/InstallationLoadCard";
@import "components/Partner/ServiziAcquistatiPartner";
@import "components/Partner/InstallationCaricaFileXmlFirmati";
@import "components/GestioneNotificheItem";
@import "components/GestioneNotificheSettings";
@import "components/GuideETutorial";
@import "components/NotificheLastList";
@import "components/NotificheEnabledSettings";
@import "components/Admin/PartnerServicesEditor";
@import "components/SendUI/style";
@import "components/SignUI/style";
