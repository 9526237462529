.add-notifica-container {
  margin: 15px 20px;
  .btn {
    padding: 4px 10px;
    font-size: 13px;
  }
}

.gestione-notifiche-item-container-title-container {
  max-width: calc(110% - 300px);
  
  .col {
    display: flex;
  }

  label {
    margin: auto 0px;
  }

  #gestione-notifiche-item-container-title {
    display: inline-block;
    width: auto;
    flex-grow: 1;
    margin-left: 12px;
  }
}

.gestione-notifiche-item-container {
  width: calc(100% - 30px);

  .gestione-notifiche-item-save-cancel-buttons-container {
    position: fixed;
    right: 70px;
    top: 180px;
    background: white;
    z-index: 100;
  }

  .gestione-notifiche-item-container-oggetto-container,
  .gestione-notifiche-item-container-destinatario-container {
    margin-bottom: 20px;
    
    > .col {
      display: flex;
      > label {
        min-width: 100px;
      }
    }
    label {
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 30px;
    }
    input {
      display: inline-block;
      width: auto;
      flex-grow: 1;
    }
    .form-check {
      display: flex;
      margin-bottom: 0;
    }
  }
  
  .gestione-notifiche-item-container-html-container {

  }

  .notifiche-sendDate-day-month {
    .notifiche-sendDate-day {
      width: 60px;
      display: inline-block;
      margin-right: 5px;
    }
    .notifiche-sendDate-month {
      width: 130px;
      display: inline-block;
      margin-left: 5px;
    }
  }   
}



// .ck-editor__main > .ck-content {
//   height: 200px;
// }