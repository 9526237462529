
.admin-row {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #fff8ed;
}

.portal-impianto {

  .installation-data-section-title {
    font-weight: bold;
    margin-top: 10px;
  }

  .installation-data-container {
    color: $gray-600;
    margin-top: 2px;

    .installation-data {
      color: $gray-800;
    }
  }

  .indirizzi-non-verificati {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .button-edit-installation,
  .button-delete-installation {
    margin-top: 10px;
  }
  

}