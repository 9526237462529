.gestione-notifiche-settings-container {
  width: calc(100% - 30px);

  .gestione-notifiche-settings-save-cancel-buttons-container {
    position: fixed;
    right: 70px;
    top: 180px;
    background: white;
    z-index: 100;
  }
}
