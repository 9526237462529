// @import url('https://fonts.googleapis.com/css?family=Montserrat|Raleway:500,500i,800,800i,900,900i|Poppins');

body {
  // background-image: url(https://www.energix.it/orbita/getimage.php?id=107);
  // font-family: 'Raleway', sans-serif;
  // font-weight: 500;
  // color: #30353a;
}

// .btn-success,
// .btn-outline-secondary {
// 	color: #fff;
// 	background-color: #30353a;
// 	border: 1px solid #30353a;
// }
// .btn-success:hover,
// .btn-success:focus,
// .btn-success.focus,
// .btn-outline-secondary:hover,
// .btn-outline-secondary:focus,
// .btn-outline-secondary.focus {
// 	color: #fff;
// 	text-decoration: none;
// 	background-color: #f5691a;
// 	border-color: #f5691a;
// }
// .btn-success:active,
// .btn-success.active,
// .btn-outline-secondary:active,
// .btn-outline-secondary.active {
// 	color: #fff;
// 	background-color: #30353a;
// 	border-color: #30353a;
// }

// .alert .btn.btn-outline-secondary {
//     margin-top: 15px;
// }

.p-4.card-body .btn-block {
  width: auto;
  float: right;
}

.form-page .form-links {
    padding-top: 70px;
}

form .p-4.card-body h1 {
  background-image: url(https://www.energix.it//orbita/getimage.php?id=111);
  background-size: 180px;
  background-repeat: no-repeat;
  padding: 80px 0 20px 0;
  text-align: right;
  color: #30353a;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  background-position: top left;
}


.service-container.row {
  /* background-color: #f5f5f5; */
  padding: 15px 15px 0;
  margin-bottom: 15px;
}

.create-service-card-container .service-container-settings .service-options-container {
    padding: 10px;
    background-color: #f5f5f5;
}

.create-service-card-container .service-container-settings .service-options-container .service-option .form-check.form-check-inline {
  margin-bottom: 0;
}

.alert-warning {
  color: #f5691a;
  /* background-color: #fff;
  border-color: transparent; */
  font-size: 16px;
  font-weight: bold;
}

.alert-warning p {
  color: #151b1e;
  font-weight: normal;
}

.create-service-card-container .service-selector .service-selector-group .group-types .service-container .service-selector-type {
  border-radius: 0;
}

.service-selector-type {
  background-image: url(https://www.energix.it/orbita/getimage.php?id=119);
  background-repeat: no-repeat;
  background-size: 35px;
  background-position: right 10px;
  background-color: #fff;
}

.create-service-card-container .service-container-price .vertical-hr {
  margin-left: -15px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.prezzo-finale {
  margin-bottom: 0;
}

.create-service-card-container .service-selector .service-selector-group .group-types .service-container .service-selector-type {
  padding: 10px 30px 10px 20px;
  margin-bottom: 0;
}

.create-service-card-container .service-selector .service-selector-group .group-types .service-container .service-selector-type .type-name {
    color: #f5691a;
    font-weight: 800;
}

.create-service-card-container .service-grand-total-price .service-total-price-value {
    font-size: 1.4em;
    color: #f5691a;
}

.create-service-card-container .service-contract-container .service-contract-iframe {
  max-height: 240px;
  min-height: 180px;
  padding: 15px;
  margin-bottom: 30px;
}

.create-service-card-container .service-grand-total-price {
  padding-top: 0;
  padding-bottom: 40px;
}

.create-service-card-container .service-contract-container {
    padding-top: 15px;
    border-top: 1px solid #dadada;
}

.service-contract-download-link {
  font-size: 10px;
  text-transform: uppercase;
}

.form-check {
  margin-bottom: 15px;
}

@media (max-width: 960px) {
  .service-selector-type {
    background-image: url(https://www.energix.it/orbita/getimage.php?id=125);
  }

  .ml-4, .mx-4, .card {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .service-container.row {
    padding: 0;
  }

  .service-container-settings {
    padding-right: 0;
    padding-left: 0;
  }

  .service-container-price {
    margin-bottom: 40px;
  }

  .create-service-card-container .service-contract-container .service-contract-iframe {
    max-height: 200px;
    min-height: 100px;
  }
}
