.energix-old-frontend-wrapper {
  @import "./App";
}

body.energix-old-frontend-wrapper-modal-open {
  // Kill the scroll on the body
  overflow: hidden;

  .energix-old-frontend-wrapper {
    .modal {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}

.popover,
.tooltip {
  z-index: 1073;
}

$brand-main-color: #f15a24;
$brand-secondary-color: #282828;
@import "../scss/partials/variables";
@import "../scss/partials/theme-elements/popover";

.popover {
  background-color: white;
  overflow: auto;
  > .arrow {
    display: none;
  }
  > .popover-inner {
    padding: 10px;
  }
}
