.portal-dashboard {

  .section-installations-title{
    .title {
      margin-bottom: 16px;
    }
    a {
      margin-top: 10px;
      margin-right: 10px;
    }
  } 

  .installation {

    // Highlight installation
    &.installation-danger .card {
      border: 3px solid $red;
    }
    &.installation-warning .card {
      border: 3px solid $yellow;
    }

    .license-container {
      color: $gray-600;
      margin-top: 2px;
      .license-name {
        color: $gray-800;
      }
    }

    .type-container {
      color: $gray-600;
      margin-top: 2px;
      .type-name {
        color: $gray-800;
      }
    }

    .status-container {
      color: $gray-600;
      margin-top: 2px;
      .status {
        color: $gray-800;
      }
    }

    .next-due-date-container {
      color: $gray-600;
      margin-top: 2px;
      .next-due-date {
        color: $gray-800;
      }
    }
    
    .do-report-button-container {
      margin-top: 8px;
    }
  
  }
}