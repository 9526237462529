$scrollbar-radius: 5px;

@mixin scrollbar-style() {
  $radius: $scrollbar-radius;
  $size: 2 * $radius;
  $base-color: #ddd;

  &::-webkit-scrollbar {
    position: absolute;
    width: $size;
    height: $size;
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar-track {
    background-color: lighten($base-color, 10%);
    border: 1px solid darken($base-color, 5%);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,.5);
    background-clip: content-box;
    border-color: transparent;
    border-style: solid;
    border-width: 2px;
  }

  &::-webkit-scrollbar-thumb:vertical {
    height: 50px;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    width: 50px;
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background-color: rgba(0,0,0,.2);
  }

  &::-webkit-scrollbar-corner {
    display: block;
    background-color: transparent;
  }
}
