.payment-info-card-container {
  .or-separator {
    display: flex;
    margin-top: 15px;
    margin-bottom: 15px;

    hr {
      flex-grow: 1;
    }
    hr.left {
      margin-left: 15%;      
    }
    hr.right {
      margin-right: 15%
    }
    span {
      margin: auto;
      color: $gray-600;
      padding: 0px 15px;
    }
  }

  .payment-info {

    .description {
      text-align: center;
      color: $gray-800;
      font-size: 15px;
    }

    .button-container {
      width: 100%;
      display: flex;
      margin-top: 8px;

      .btn {
        margin-left: auto;
        margin-right: auto;
        background: white;
      }  
    }

    .dati-bonifico-container {
      margin-top: 8px;
      display: flex;
      flex-direction: row;

      .dati-bonifico {
        margin-left: auto;
        margin-right: auto;
        color: $gray-700;
        font-size: 14px;
      }
    }

    .alert {
      margin-left: auto;
      margin-right: auto;
      margin-top: 10px;
    }
  }
}