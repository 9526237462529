.sign-process-root {
  .sign-process-info {
    margin-top: 20px;
    margin-bottom: 20px;

    .sign-process-info-title {
      font-size: 1.2rem;
    }
  }

  .sign-process-connected {
    font-size: 1.05rem;
    color: #28a745;
    margin: 10px 20px 30px;
  }

  .sign-app-list-title {
    margin: 0px 20px 10px;
    font-weight: 600;
  }
}