
$sidebar-nav-link-bg: $menu-light-background;
$sidebar-nav-dropdown-bg: $menu-light-background;

.b-brand .energix-icon-container {
  width: 108px;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.pcoded-header .dropdown .profile-notification .pro-body li a svg {
  margin-right: 10px;
}

.dropdown-item {
  &:active, &.active {
      color: $dark-layout-font;
      background-color: transparentize($dark-layout-font, 0.9);
  }
}


.form-control:disabled, .form-control[readonly] {
  background: lighten($theme-background, 1%);
  color: darken($dark-layout-font, 3%);
}

main.main.pcoded-main-container {
  overflow-y: auto;
  // height: calc(100vh - #{$header-height});
  // min-height: calc(100vh - #{$header-height});
  height: calc(100vh);
  min-height: calc(100vh);
}
