
.sidebar nav {
  li.nav-item a.nav-link {
    // display: flex;
    display: block;
    word-break: break-word;
    cursor: pointer;

    i, svg {
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 0.4rem;
      flex-shrink: 0;
    }    
  }
}


.sidebar .nav-dropdown.open {
  background: $sidebar-nav-link-bg;
  border-top: 1px solid $sidebar-nav-dropdown-bg;
  margin-top: -1px;
  .nav-dropdown.open {
    border-top: none;
    margin-top: 0px;
  }
}


.sidebar .nav-item .nav-link { 
  .nav-link-hover-button {
    position: absolute;
    right: 10px;
    visibility: hidden;
    float: right;
    padding-left: 15px;
  }

  &:hover .nav-link-hover-button {
    visibility: visible;
    
    &, & > svg {
      color: darken(theme-color("primary"), 12%);
    }

    &:hover {
      &, & > svg {
        color: darken(theme-color("primary"), 20%);
      }        
    }
  }
}


.sidebar .divider { 
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid $gray-200;
}

.sidebar .datta-scroll {
  height: calc(100vh) !important;
  overflow-y: auto;
  .scrollbar-container {
    height: unset;
  }
}


.sidebar .nav-dropdown-items.nav-dropdown-level-2 {
  .MuiListItemButton-root {
    padding-left: 35px;    
  }
}
