.app-header.navbar {
  .navbar-brand div.icon {
    display: inline-block;
    position: relative;
    margin-right: 5px;
    vertical-align: middle;
    height: 38px;
    width: 100%;
  }

  &.admin-logged-as-user {
    &, .navbar-brand {
      background: #ffc97b66 !important;
    }

    .admin-logged-as-user-text {
      margin-left: 60px;
      margin-right: 60px;
      font-weight: bold;
      font-size: 1.2em;
    }
  }

  // // Fix Mobile layout
  @include media-breakpoint-down(md) {
    .navbar-brand {
      position: initial;
      left: unset;
      margin-left: 0px;
    }
  }
  @media (max-width: 700px) {  
    // .navbar-brand {
    //   position: absolute;
    //   left: 50%;
    //   margin-left: - ($navbar-brand-width / 2);
    // }
    ul.navbar-nav {
      > li {
        display: none;
      }
      > li.connection-status-dropdown {
        display: list-item;
      }
    }
  }
  
  
  ul.navbar-nav.ml-auto {
    li.nav-item {
      margin-right: 12px !important;
      p {
        white-space: normal;
      }
    }    
    li.nav-item:last-child {
      margin-right: 5px !important;
      min-width: 35px !important;
    }
    .dropdown-menu {
      position: absolute;
      right: 0px;
      left: auto;
    }

    > li.nav-item > a > svg{
      margin-right: 2px;
    }
  }

  .user-nav-button {
    margin-left: 10px;

    span {
      // margin-right: 7px;
    }

    @media (max-width: 500px) {  
      .user-nav-button-username {
        display: none;
      }
    }
  }
}


.app.app-header-full-width > .app-header.navbar {
  margin-left: 0px;
  width: 100%;
}